body {
  margin: 0;
  padding: 0;
  font-family: 'CS PraKas';
  font-size: 20px;
  color: #252525;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

thead tr th, thead tr td {
  font-size: 19px;
  font-style: bold;
  font-family: 'CS PraKas';
  color: #252525;
}

tbody tr th, tbody tr td {
  font-size: 17px;
  font-family: 'CS PraKas';
  color: #252525;
}

ul li a {
  font-size: 18px;
  font-family: 'CS PraKas';
  color: #252525;
}

input.has-error {
  border: solid 1px rgb(245, 34, 45);
}

div.ant-tabs-tab {
  font-family: 'CS PraKas';
  color: #252525;
  font-size: 19px;
}

textarea.has-error {
  border: solid 1px rgb(245, 34, 45);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button:disabled {
  border-color: #e6e6e6;
  cursor: not-allowed;
}

button:disabled svg {
  color: #e6e6e6;
}

button:disabled:hover {
  border-color: #e6e6e6;
}

td.ant-table-row-expand-icon-cell:empty {
  display: none;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side,#1890ff 94%,#0000) top/9px 9px no-repeat,
         conic-gradient(#0000 30%,#1890ff);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
     transform: rotate(1turn);
  }
}