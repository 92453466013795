.adv-search-container {
  max-height: 0;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  transition: max-height 0.5s;
}

.adv-search-container.hide {
	overflow: hidden;
  max-height: 0;
}

.adv-search-container.show {
	max-height: 800px;
}