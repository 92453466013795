body {
  background: transparent url("../images/bg-body.png") no-repeat center top
    fixed;
  background-size: 100%;
}
header {
  color: white;
  padding: 1rem 0;
  .img {
    &-logo {
      display: inline-block;
      width: unset;
      height: unset;
      margin-right: 1rem;
    }
    &-user {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      object-fit: cover;
      -o-object-fit: cover;
      margin-right: 0.5rem;
      border-radius: 10rem;
    }
  }
  .name-user {
    margin-right: 0.5rem;
  }
}
footer {
  background-color: $bg-footer;
  text-align: center;
  font-size: 80%;
  padding: 0.5rem;
}

.img {
  &-logo {
    width: 6.25rem;
    height: 6.25rem;
    object-fit: cover;
    -o-object-fit: cover;
  }
  &-link {
    @include hover-focus {
      opacity: 0.8;
    }
  }
}

.card {
  &-login {
    padding: 4.5rem 3.75rem;
    border: unset;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    box-shadow: $box-shadow;
  }
}
.form-login {
  .form-group {
    margin-bottom: 2rem;
  }
  .ant-input-prefix {
    left: 0;
    font-size: 1.5rem;
    i {
      color: $login-color !important;
    }
  }
  .ant-input {
    padding-left: 2.5rem !important;
    border: unset;
    border-radius: unset;
    border-bottom: 0.1rem solid $login-color;
    @include hover-focus {
      border-color: lighten($login-color, 10%) !important;
    }
    &:focus {
      box-shadow: unset;
    }
  }
  .login-form-button {
    margin-top: 2.5rem;
    font-weight: bold;
    background-color: $login-color;
    border-color: darken($login-color, 5%);
    @include hover-focus {
      background-color: lighten($login-color, 5%);
      border-color: $login-color;
    }
    &:active {
      background-color: darken($login-color, 5%);
    }
  }
}
.input-alert {
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}
#root {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.img-icon {
  display: inline-block;
  height: 1rem;
}
.list-info {
  color: white;
  li {
    margin-bottom: 0.5rem;
    .img-icon {
      height: 1.25rem;
      margin-right: 0.5rem;
    }
    h5 {
      display: inline-block;
      margin-bottom: 0;
      font-weight: normal;
    }
  }
}
.card-menu {
  border: unset;
  border-radius: 0.5rem;
  padding: 1rem;
  display: -ms-flexbox;
  display: flex;
  box-shadow: $box-shadow;
  height: calc(100% - 1.875rem);
  margin-bottom: 1.875rem;
  @include transition($transition-base);
  @include hover-focus {
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05);
    box-shadow: $box-shadow-lg;
    @include transition($transition-base);
  }
  .menu {
    &-icon {
      margin-right: 1rem;
      text-align: center;
    }
    &-title {
      color: white;
      * {
        margin-bottom: unset;
      }
      h4 {
        font-weight: normal;
      }
    }
  }
  &-schedule {
    padding: 1.5rem 1rem;
    background: white url("../images/bg/bg-menu-schedule.png") no-repeat center
      center;
    background-size: cover;
  }
  &-statistics {
    padding: 1.5rem 1rem;
    background: white url("../images/bg/bg-menu-statistics.png") no-repeat
      center center;
    background-size: cover;
  }
  &-report {
    background: white url("../images/bg/bg-menu-report.png") no-repeat right top;
    background-size: contain;
    .menu-title {
      h3 {
        color: $info;
      }
      h5 {
        color: $text-muted;
      }
    }
  }
  &-form {
    background: white url("../images/bg/bg-menu-form.png") no-repeat right top;
    background-size: contain;
    .menu-title {
      h3 {
        color: $success;
      }
      h5 {
        color: $text-muted;
      }
    }
  }
  &-dashboard {
    background: white url("../images/bg/bg-menu-dashboard.png") no-repeat right
      top;
    background-size: contain;
    .menu-title {
      h3,
      h4 {
        color: $dark;
      }
      h5 {
        color: $text-muted;
      }
    }
  }
}
.container-content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1920px) {
    max-width: 1800px;
  }
}

.bg {
  &-info {
    &-light {
      background-color: rgba($info, 0.2);
    }
  }
  &-primary {
    &-light {
      background-color: rgba($primary, 0.2);
    }
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   // margin-top: 0;
//   // margin-bottom: 0.5em;
//   color: rgba(255, 255, 255, 0.85);
//   //font-weight: 500;
// }

.custom-timeline {
  display: block;
  overflow: hidden;
  .react-calendar-timeline {
    width: 100%;
    overflow: hidden;
  }
  .rct-label-group span {
    display: none !important;
  }
}

.custom-ant-carousel {
  padding: 0 25px;
  .ant-carousel {
    .slick {
      &-arrow {
        opacity: 0.5;
        @include transition($transition-base);
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        &:hover {
          opacity: 1;
          @include transition($transition-base);
        }
      }
      &-prev {
        &::before {
          background: url("../images/icons/icon-arrow-prev.png");
        }
      }
      &-next {
        &::before {
          background: url("../images/icons/icon-arrow-next.png");
        }
      }
    }
  }
}
