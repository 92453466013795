.ant-carousel .slick-slide {
  text-align: center;
  height: auto;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: red;
}

.ant-carousel .slick-slider .slick-dots li button {
  background: #4447ab;
  width: 15px;
  height: 15px;
  margin: 2px;
  margin-top: 10px;
  border-radius: 10px;
}
/* .ant-carousel .slick-slider .slick-dots li:last-child {
  display: none;
} */
/* .ant-carousel .slick-slide[data-index="-1"] {
  display: none;
} */

// .ant-carousel .slick-arrow,
// .ant-carousel .slick-arrow:focus {
//   background-color: #8c8c8c;
//   width: 1.5rem;
//   height: 1.5rem;
//   border-radius: 10rem;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
// }
// .ant-carousel .slick-arrow:hover {
//   background-color: #8c8c8c;
//   opacity: 0.6;
// }
// .ant-carousel .slick-arrow::before {
//   position: static;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   font-size: 1rem;
//   color: white;
// }
// .ant-carousel .slick-prev:before {
//   content: "\276E" !important;
//   left: 0;
// }
// .ant-carousel .slick-next:before {
//   content: "\276F" !important;
//   right: 0;
// }
.ant-carousel .slick-list .slick-slide {
  pointer-events: auto !important;
}
