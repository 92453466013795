.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    &:hover {
      color: $success;
    }
    &-checked:not(.ant-radio-button-wrapper-disabled) {
      background: $success;
      border-color: $success;
      &:hover {
        background: lighten($success, 5%);
        border-color: lighten($success, 5%);
      }
    }
  }
}
