.ant-modal {
  &-header {
    background: $primary;
    .ant-modal-title {
      color: white;
      font-size: 1.875rem;
    }
  }
  &-footer {
    border-top: unset;
  }
}
