$primary: #565eba;
$secondary: #345375;
$success: #15bcc9;
$info: #248ced;
$warning: #fda342;
$danger: #f16c72;
$dark: #4e4e4e;
$light: #f2f2f2;

$bg-navbar: #3e419d;
$login-color: #345375;
$bg-footer: #f3f5f8;

// Table
$th-bg: #e6e8ff;
$th-color: #525680;
$th-primary: #cdd0fb;
$th-primary-light: #d7dafe;
$th-success: #bdf5fe;
$th-success-light: #d1f7fd;

// Form
$tag-color: #1bb4e3;

$font-family-base: "CS PraKas";
$headings-font-weight: bold;
$link-hover-decoration: none;
$fa-font-path: "../fonts/webfonts";
