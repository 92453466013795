.thead-uppercase {
  thead {
    tr {
      th {
        // white-space: nowrap;
        text-transform: uppercase;
      }
    }
  }
}
.ant-table-thead tr {
  th {
    background: $th-bg;
    color: $th-color;
    border-bottom-color: $light;
    text-align: center;
  }
  td {
    border-bottom-color: $light;
  }
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom-color: $light;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right-color: $light;
}
.tbody-center {
  tbody tr td {
    text-align: center;
  }
}

.ant-table-wrapper {
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: $th-bg;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba($primary, 0.5);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba($primary, 1);
  }
}
// .ant-table-scroll {
//   .ant-table-body {
//     overflow-y: auto !important;
//   }
// }

.ant-table {
  .table {
    &-primary {
      background-color: $th-primary !important;
      color: rgba(black, 0.75) !important;
      &-light {
        background-color: $th-primary-light !important;
        color: rgba(black, 0.75) !important;
      }
    }
    &-success {
      background-color: $th-success !important;
      color: rgba(black, 0.75) !important;
      &-light {
        background-color: $th-success-light !important;
        color: rgba(black, 0.75) !important;
      }
    }
  }
}

.remark-overflow{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
