.navbar-menu {
  background: $bg-navbar;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow: hidden;
  .nav-item {
    text-align: unset;
    .nav-link {
      height: 100%;
      color: white;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;

      &:hover {
        background: lighten($bg-navbar, 5%);
      }
      &.active,
      &:focus {
        background: darken($bg-navbar, 10%);
      }
      img {
        margin-right: 1rem;
        max-width: 100%;
      }
      h5 {
        font-weight: normal;
        margin-bottom: unset;
      }
      @media (max-width: 1399px) {
        img {
          max-width: 1.5rem;
        }
        h5 {
          font-size: 1rem;
        }
      }
      @include media-breakpoint-down(lg) {
        h5 {
          display: none;
        }
      }
    }
  }
}
.ant-dropdown {
  &-menu {
    overflow: hidden;
    margin-top: -4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: unset;
    background-color: darken($bg-navbar, 10%);
    box-shadow: $box-shadow-sm;
    &-item {
      a {
        color: white;
      }
      &:hover {
        background-color: $bg-navbar;
      }
    }
  }
}
