.DayPicker-Day {
  font-size: 14px;
  margin: 5px !important;
}

.DayPicker {
  border: solid 1px #e6e8ff;
  border-radius: 3px;
}

.DayPicker-Caption {
  background-color: #e6e8ff;
  margin: -15px -15px 0 -15px;
  padding: 5px;
}

.DayPicker-Caption > div {
  color: #525680;
  text-align: center;
  font-weight: bold;
}

.DayPicker-NavBar {
  display: none;
}