@font-face {
  font-family: "CS PraKas";
  src: url("../fonts/CSPraKas-Bold.woff2") format("woff2"),
    url("../fonts/CSPraKas-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "CS PraKas";
  src: url("../fonts/CSPraKas.woff2") format("woff2"),
    url("../fonts/CSPraKas.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@mixin font-icon-($weight) {
  font-family: "Font Awesome 5 Free";
  font-weight: $weight;
}
