.card {
  &-body {
    &-component {
      background: white url('../images/bg/bg-card-body-componentr.png')
        no-repeat center top;
      background-size: 100%;
      border: unset;
      border-radius: 0.5rem;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    }
  }
  &-header {
    &-table {
      background: rgba($th-bg, 0.5);
      border: unset;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
  }
}
