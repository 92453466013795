.tag-status {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #ddd;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: white;
  &-arrival {
    background-color: #fda342;
  }
  &-departure {
    background-color: #15bcc9;
  }
  + .tag-status {
    margin-left: 4px;
  }
}
