.tabs-button {
  .ant-tabs-bar {
    border-bottom: unset;
    margin-bottom: unset;
    .ant-tabs-nav {
      border: 1px solid $light;
      box-shadow: $box-shadow-sm;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      .ant-tabs-tab {
        margin-right: unset;
        background-color: white;
        @include button-size(
          $btn-padding-y,
          $btn-padding-x,
          $font-size-base,
          $btn-line-height,
          unset
        );
        &:hover {
          color: $success;
        }
        &-active {
          background-color: $success;
          color: white;
          &:hover {
            color: white;
          }
        }
      }
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
}

.antd-table-custom .ant-table{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  .ant-table-scroll{
  }
}

// @media (max-width: 600px){
//   .ant-table-content{
//     display: block;
//     width: 100%;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
  
//     .ant-table-scroll{
    
//     }
  
//   }
// }

// .antd-table-responsive-custom {
//   display: block;

//   @media screen and (max-width: 600px) {
//     &-thead {
//       display: none;
//     }

//     &-thead>tr,
//     &-tbody>tr {

//       th,
//       td {
//         &:first-of-type {
//           padding-top: 1rem;
//         }

//         &:last-of-type {
//           padding-bottom: 1rem;
//         }
//       }

//       >th,
//       >td {
//         display: block;
//         width: auto !important;
//         border: none;
//         padding: 0 1rem;
//         font-size: 1.1rem;

//         &:last-child {
//           border-bottom: 1px solid #eee;
//         }
//       }
//     }
//   }
// }