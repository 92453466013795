
.card-header-table .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
  width: 150px;
}

.card-header-table .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab:nth-child(1):hover {
  color: #fa9134;
}

.card-header-table .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-active.ant-tabs-tab:nth-child(1) {
  background-color: #fa9134;
}

.card-header-table .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-active.ant-tabs-tab:nth-child(1):hover {
  color: #fff;
}
