.ant-select-selection--multiple {
  .ant-select-selection__choice {
    background-color: rgba($tag-color, 0.25);
    border-color: $tag-color;
    color: darken($tag-color, 15%);
    &__remove {
      color: darken($tag-color, 15%);
      .anticon {
        vertical-align: unset;
      }
    }
  }
}
.ant-checkbox-group {
  .ant-checkbox-group-item {
    display: inline-block;
  }
}

.ant-select-arrow-icon,
.ant-calendar-picker-icon,
.ant-time-picker-clock-icon {
  color: $success;
}

.ant-radio-group {
  .ant-radio-wrapper {
    display: inline-block;
  }
}

.col-form-label {
  font-weight: bold;
}
