.ant-dropdown-link {
  cursor: pointer;
}

.flag-container {
  margin-left: 20px;
  color: #fff;
}

.flag-container .down svg {
  margin-top: -5px;
}

.header-flag {
  color: #fff;
  width: 65px !important;
}

.header-flag .img,
.flag-container .img {
  width: 20px;
}
@media (max-width: 767.98px) {
  .header-text {
    display: none;
  }
}

.img-link{
  cursor: pointer;
}